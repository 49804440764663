<template>
  <b-card no-body>
    <!-- Add Message Form -->
    <div class="message-form-container px-3 py-2 mb-1 border-primary mx-3 my-2">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col
            lg="3"
            md="6"
          >
            <b-form-group label="Title">
              <b-form-input
                v-model="form.title"
                required
                placeholder="Enter message title"
              />
              <b-form-invalid-feedback v-if="!titleState && form.title.length > 0">
                Title must be at least 3 characters
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col
            lg="5"
            md="6"
          >
            <b-form-group label="Message">
              <b-form-textarea
                v-model="form.message"
                required
                placeholder="Enter message content"
                rows="2"
              />
              <b-form-invalid-feedback v-if="!messageState && form.message.length > 0">
                Message must be at least 5 characters
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col
            lg="2"
            md="6"
          >
            <b-form-group label="Priority">
              <b-form-select
                v-model="form.priority"
                :options="priorityOptions"
                required
              />
            </b-form-group>
          </b-col>
          <b-col
            lg="2"
            md="6"
            class="d-flex flex-column"
          >
            <b-form-group
              label="Status"
              class="mb-auto"
            >
              <b-form-checkbox
                v-model="form.is_active"
                switch
                size="lg"
              >
                {{ form.is_active ? 'Active' : 'Inactive' }}
              </b-form-checkbox>
            </b-form-group>
            <div class="mt-2">
              <b-button
                type="submit"
                variant="primary"
                class="w-100"
                :disabled="submitting || !isFormValid"
              >
                <b-spinner
                  v-if="submitting"
                  small
                />
                {{ submitting ? 'Adding...' : 'Add Message' }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </div>

    <!-- Loading/Error Alert -->
    <b-alert
      v-if="loadingError"
      show
      variant="danger"
      dismissible
    >
      {{ loadingError }}
    </b-alert>

    <!-- Messages Tabs -->
    <b-tabs
      pills
      card
      class="mx-2"
    >
      <b-tab
        title="All Messages"
        active
      >
        <!-- Message List Items -->
        <div
          v-for="message in filteredMessages"
          :key="message.id"
          class="message-item p-1 mb-1"
        >
          <b-row>
            <b-col md="5">
              <div>
                <h6 class="mb-1">
                  {{ message.title }}
                </h6>
                <p class="mb-0">
                  {{ message.message }}
                </p>
              </div>
            </b-col>
            <b-col
              md="7"
              class="d-flex justify-content-end align-items-center"
            >
              <b-badge
                :variant="getPriorityVariant(message.priority)"
                class="mr-2"
              >
                {{ message.priority }}
              </b-badge>
              <b-badge
                :variant="message.is_active ? 'success' : 'secondary'"
                class="mr-2"
              >
                {{ message.is_active ? 'Active' : 'Inactive' }}
              </b-badge>
              <b-form-checkbox
                v-model="message.is_active"
                switch
                size="lg"
                class="mb-1"
                @change="updateStatus(message)"
              />
              <b-button-group size="sm">
                <b-button
                  variant="outline-primary"
                  size="sm"
                  @click="openEditModal(message)"
                >
                  EDIT
                </b-button>
                <b-button
                  variant="outline-danger"
                  size="sm"
                  @click="confirmDelete(message)"
                >
                  DELETE
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>
        </div>

        <div
          v-if="filteredMessages.length === 0"
          class="text-center p-4"
        >
          <p class="text-muted">
            No messages found.
          </p>
        </div>
      </b-tab>

      <b-tab title="Active Messages">
        <!-- Active Message List Items -->
        <div
          v-for="message1 in activeMessages"
          :key="message1.id"
          class="message-item p-1 mb-1"
        >
          <b-row>
            <b-col md="5">
              <div>
                <h6 class="mb-1">
                  {{ message1.title }}
                </h6>
                <p class="mb-0">
                  {{ message1.message }}
                </p>
              </div>
            </b-col>
            <b-col
              md="7"
              class="d-flex justify-content-end align-items-center"
            >
              <b-badge
                :variant="getPriorityVariant(message1.priority)"
                class="mr-2"
              >
                {{ message1.priority }}
              </b-badge>
              <b-badge
                :variant="message1.is_active ? 'success' : 'secondary'"
                class="mr-2"
              >
                {{ message1.is_active ? 'Active' : 'Inactive' }}
              </b-badge>
              <b-form-checkbox
                :checked="message1.is_active"
                switch
                size="lg"
                class="mb-1"
                @change="handleActiveTabToggle(message1)"
              />
              <b-button-group size="sm">
                <b-button
                  variant="outline-primary"
                  size="sm"
                  @click="openEditModal(message1)"
                >
                  EDIT
                </b-button>
                <b-button
                  variant="outline-danger"
                  size="sm"
                  @click="confirmDelete(message1)"
                >
                  DELETE
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>
        </div>

        <div
          v-if="activeMessages.length === 0"
          class="text-center p-4"
        >
          <p class="text-muted">
            No active messages found.
          </p>
        </div>
      </b-tab>
    </b-tabs>

    <!-- Edit Message Modal -->
    <b-modal
      id="edit-message-modal"
      title="Edit Message"
      size="lg"
      :hide-footer="true"
      @hidden="resetForm"
    >
      <b-form @submit.prevent="updateMessage">
        <b-form-group label="Title">
          <b-form-input
            v-model="editForm.title"
            required
            placeholder="Enter message title"
          />
        </b-form-group>

        <b-form-group label="Message">
          <b-form-textarea
            v-model="editForm.message"
            required
            placeholder="Enter message content"
            rows="4"
          />
        </b-form-group>

        <b-form-group label="Priority">
          <b-form-select
            v-model="editForm.priority"
            :options="priorityOptions"
            required
          />
        </b-form-group>

        <b-form-group>
          <b-form-checkbox
            v-model="editForm.is_active"
            switch
          >
            {{ editForm.is_active ? 'Active' : 'Inactive' }}
          </b-form-checkbox>
        </b-form-group>

        <div class="d-flex justify-content-end mt-3">
          <b-button
            variant="secondary"
            class="mr-2"
            @click="$bvModal.hide('edit-message-modal')"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            type="submit"
            :disabled="updating"
          >
            <b-spinner
              v-if="updating"
              small
            />
            {{ updating ? 'Updating...' : 'Update Message' }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import {
  BCard, BTab, BTabs, BAlert, BModal, BForm, BFormInput, BFormTextarea,
  BFormGroup, BFormSelect, BFormCheckbox, BButton, BSpinner, BRow, BCol,
  BBadge, BButtonGroup, BFormInvalidFeedback,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BAlert,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BFormSelect,
    BFormCheckbox,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BBadge,
    BButtonGroup,
    BFormInvalidFeedback,
  },
  data() {
    return {
      // Form data
      form: {
        title: '',
        message: '',
        is_active: true,
        priority: 'medium',
      },

      // Edit modal data
      editForm: {
        id: null,
        title: '',
        message: '',
        is_active: true,
        priority: 'medium',
      },

      // Messages data
      messages: [],

      // UI state
      loadingError: null,
      loading: false,
      submitting: false,
      updating: false,
      priorityFilter: null,

      // Options
      priorityOptions: [
        { value: 'low', text: 'Low' },
        { value: 'medium', text: 'Medium' },
        { value: 'high', text: 'High' },
        { value: 'urgent', text: 'Urgent' },
      ],
    }
  },
  computed: {
    // Form validation
    titleState() {
      return this.form.title.length >= 3 || this.form.title.length === 0
    },
    messageState() {
      return this.form.message.length >= 5 || this.form.message.length === 0
    },
    isFormValid() {
      return this.form.title.length >= 3 && this.form.message.length >= 5
    },

    // Filtered message lists
    filteredMessages() {
      if (!this.priorityFilter) {
        return this.messages
      }
      return this.messages.filter(message => message.priority === this.priorityFilter)
    },
    activeMessages() {
      return this.messages.filter(message => message.is_active)
    },
  },
  created() {
    this.fetchMessages()
  },
  methods: {
    // Load all messages
    async fetchMessages() {
      this.loading = true
      this.loadingError = null
      try {
        const { data } = await axios.get('/retailers/broadcast_message/')
        this.messages = data
      } catch (error) {
        this.loadingError = error?.response?.data?.error || 'Failed to load messages. Please try again.'
      } finally {
        this.loading = false
      }
    },

    // Add a new message
    async submitForm() {
      if (!this.isFormValid) return

      this.submitting = true
      try {
        await axios.post('/retailers/broadcast_message/', this.form)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Message added successfully',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        // Reset form
        this.form = {
          title: '',
          message: '',
          is_active: true,
          priority: 'medium',
        }

        // Refresh message list
        await this.fetchMessages()
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.response?.data?.message || 'Failed to add message. Please try again.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.submitting = false
      }
    },

    // Update message status using PATCH for the All Messages tab
    async updateStatus(message) {
      // Store original status before API call
      const originalStatus = message.is_active

      try {
        // Create a payload with just the updated status
        const payload = {
          is_active: message.is_active,
        }

        // Call the API first, before any UI updates
        await axios.patch(`/retailers/broadcast_message/${message.id}/`, payload)

        // Show success notification
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Message ${message.is_active ? 'activated' : 'deactivated'} successfully`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        // Revert the local state change if API call fails
        this.$set(message, 'is_active', originalStatus)

        // Show error notification
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.response?.data?.message || 'Failed to update status',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },

    // Handler specifically for the Active Messages tab
    async handleActiveTabToggle(message) {
      this.$set(message, 'is_active', !message.is_active)

      try {
        // Create a payload with just the updated status
        const payload = {
          is_active: message.is_active,
        }

        // Call the API to update status
        await axios.patch(`/retailers/broadcast_message/${message.id}/`, payload)

        // Show success notification
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Message ${message.is_active ? 'activated' : 'deactivated'} successfully`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        // Since we deactivated an item that should no longer be in the active tab,
        // refresh the entire list after a brief delay to allow the API to complete
        if (!message.is_active) {
          setTimeout(() => {
            this.fetchMessages()
          }, 300)
        }
      } catch (error) {
        // Revert the toggle if API call fails
        this.$set(message, 'is_active', !message.is_active)
        // Show error notification
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.response?.data?.message || 'Failed to update status',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },

    // Edit message functions
    openEditModal(message) {
      this.editForm = { ...message }
      this.$bvModal.show('edit-message-modal')
    },

    resetForm() {
      this.editForm = {
        id: null,
        title: '',
        message: '',
        is_active: true,
        priority: 'medium',
      }
    },

    async updateMessage() {
      this.updating = true
      try {
        await axios.put(`/retailers/broadcast_message/${this.editForm.id}/`, this.editForm)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Message updated successfully',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$bvModal.hide('edit-message-modal')
        await this.fetchMessages()
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.response?.data?.message || 'Failed to update message. Please try again.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.updating = false
      }
    },

    // Delete message functions
    confirmDelete(message) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this message?', {
        title: 'Confirm Delete',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            this.deleteMessage(message.id)
          }
        })
    },

    async deleteMessage(id) {
      try {
        await axios.delete(`/retailers/broadcast_message/${id}/`)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Message deleted successfully',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        // Refresh message list
        await this.fetchMessages()
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.response?.data?.message || 'Failed to delete message. Please try again.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },

    // Utility functions
    getPriorityVariant(priority) {
      const variants = {
        low: 'secondary',
        medium: 'info',
        high: 'warning',
        urgent: 'danger',
      }
      return variants[priority] || 'primary'
    },
  },
}
</script>

<style scoped>
.message-form-container {
  background-color: #f8f9fa;
  border-radius: 4px;
}

.message-item {
  background-color: white;
  border: 1px solid #e9ecef;
  border-radius: 3px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.message-item:hover {
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

/* Removed priority indicator styles as requested */
</style>
